@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,500;0,600;0,700;1,400;1,600&display=block');
@font-face {
  font-family: 'Tahoma';
  src: url('./components/font/Tahoma.eot');
  src: url('./components/font/Tahoma.eot?#iefix') format('embedded-opentype'),
      url('./components/font/Tahoma.woff2') format('woff2'),
      url('./components/font/Tahoma.woff') format('woff'),
      url('./components/font/Tahoma-Regular.ttf')  format('truetype'),
      url('./components/font/Tahoma.svg#Tahoma') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Tahoma';
  src: url('./components/font/Tahoma-Bold.eot');
  src: url('./components/font/Tahoma-Bold.eot?#iefix') format('embedded-opentype'),
      url('./components/font/Tahoma-Bold.woff2') format('woff2'),
      url('./components/font/Tahoma-Bold.woff') format('woff'),
      url('./components/font/Tahoma-Bold.ttf')  format('truetype'),
      url('./components/font/Tahoma-Bold.svg#Tahoma-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

body {
  margin: 0;
  font-family: 'Tahoma';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  cursor: pointer;
}
#root #cnct {
  color: #fff;
  font-size: 29px;
  line-height: 40px;
  font-weight: 400;
  border-radius: 50px;
  border:0;
  padding: 20px;
  width: 25vw;
  font-family: 'Tahoma';
  -webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}
#root #cnct:hover {
	box-shadow: none;
	color: #222223;
	background: #fff;
}
#root header #cnct {
	width: auto;
	padding: 12px 50px;
    font-size: 26px;
	margin-right: 25px;
}

#root .ButtonRegular:hover {
	box-shadow: none;
	color: #fff;
    background: #222223;
	border-color:#222223;
}
#root .dark .ButtonRegular:hover {
	box-shadow: none;
	color: #222223;
    background: #fff;
	border-color:#fff;
}
#root .ButtonRegularAlt:hover {
	box-shadow: none;
	color: #fff;
    background-color: #FBA300;
}
#root .drop_menu:focus {
    box-shadow: none;
}
body .drop_menu_wrap {
    top: 0 !important;
    max-height: 100vh !important;
    background-color: #222223;
}
/* .drop_menu_wrap .kVFtzT {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    align-items: center;
} */
/* .drop_menu_wrap .iuBOfw {
    align-items: end;
} */
.drop_menu_wrap button {
  padding: 24px 31px;
}
@media all and (max-width:1680px) {
    #root header #cnct {
        padding: 12px 40px;
        font-size: 20px;
        line-height: 24px;
    }
    #root .top_banner_home_medium #cnct {
        font-size: 22px;
        line-height: 24px;
        padding: 18px 60px;
        width: auto;
    }
}
@media all and (max-width:1170px) {
  #root .top_banner_home_small #cnct {
    width: auto;
    padding: 18px 50px;
    font-size: 24px;
    line-height: 26px;
  }
  .drop_menu_wrap > div > .StyledBox-sc-13pk1d4-0:first-child {
    align-items: end;
  }
  .drop_menu_wrap > div > .StyledBox-sc-13pk1d4-0:last-child {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    align-items: center;
  }
  .drop_menu_wrap > div > .StyledBox-sc-13pk1d4-0:nth-child(even) {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    align-items: center;
  }
  .drop_menu_wrap .menu_link {
    color: #fff;
    font-size: 20px;
    line-height: 50px;
  }
  .drop_menu_wrap .menu_link:hover,
  .drop_menu_wrap .menu_link:focus {
    background-color: transparent;
    color: #FBA300;
  }
  .drop_menu_wrap .menu_link > div {
      padding: 0;
  }
}
@media all and (max-width:992px) {
    #root .top_banner_home_small #cnct {
        font-size: 20px;
        line-height: 22px;
        padding: 12px 10px 14px;
        width: 100%;
        max-width: 265px;
    }
    #root .mainFooter {
        display: block;
        text-align: center;
    }
}
